import { useQuery } from '@tanstack/react-query'
import { promotionsApi } from '@pig-casino/api'
import { IPromotionsItem } from '@pig-casino/types/Promotions.type'
import { camelizeKeys } from '@pig-frontend/utils'

interface ListPromotionsType {
  params?: promotionsApi.IListParamsProps
  select?: (data: IPromotionsItem[]) => IPromotionsItem[]
  enabled?: boolean
}

export default function useListPromotion({
  params = {},
  select,
  enabled,
}: ListPromotionsType) {
  return useQuery({
    queryKey: [promotionsApi.key.list],
    queryFn: async () => {
      const result = await promotionsApi.list(params)
      return Array.isArray(result?.data?.results)
        ? (result?.data?.results as IPromotionsItem[])
        : []
    },
    enabled: enabled,
    select: (data) =>
      select ? select(data) : (camelizeKeys(data) as IPromotionsItem[]) || [],
  })
}
