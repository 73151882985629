import { useQuery } from '@tanstack/react-query'
import { campaignApi } from '@pig-casino/api'
import { ICampaignsResultData } from '@pig-casino/types/Campaign.type'
import { camelizeKeys } from '@pig-frontend/utils'
import { IChampionItem } from '@pig-casino/types/Champion.type'

interface ListCampaignType {
  params?: campaignApi.IParamsProps
  select?: (data: IChampionItem[]) => ICampaignsResultData[]
  enabled?: boolean
}

export default function useCampaign({
  params,
  select,
  enabled,
}: ListCampaignType) {
  return useQuery({
    queryKey: [campaignApi.key.list],
    queryFn: async () => {
      const result = await campaignApi.list(params)
      return Array.isArray(result?.data?.results)
        ? (result?.data?.results as IChampionItem[])
        : []
    },
    enabled: enabled,
    select: (data) =>
      select
        ? select(data)
        : (camelizeKeys(data) as ICampaignsResultData[]) || [],
  })
}
